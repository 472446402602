.stylistic-quote-mark {
  font-size: 5rem;
  right: 100%;
  @apply mr-6 hidden font-serif text-neutral-500 absolute top-0 leading-none;
}

@screen sm {
  .stylistic-quote-mark {
    @apply block;
  }
}
