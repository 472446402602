:root {
  --color-primary: 0 0 0;
  --color-secondary: 0 0 0;
  --color-tertiary: 0 0 0;
  --color-quaternary: 0 0 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-weight: bold;
}

html {
  /* we "hide" pages before the CSS loads because some browsers experience a FOUC, which is bad */
  display: block !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.vjs-vtt-thumbnail-display {
  position: absolute;
  transition:
    transform 0.1s,
    opacity 0.2s;
  bottom: 85%;
  pointer-events: none;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
}
