@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .cc-btn-sm {
    @apply bg-blue-600 hover:bg-blue-800 h-8 pl-3 pr-3 min-h-8 text-sm inline-flex flex-shrink-0 cursor-pointer select-none flex-wrap items-center justify-center text-center rounded-btn leading-4 gap-2 font-semibold no-underline text-white;
  }

  .cc-btn-primary {
    @apply py-2 px-4 text-center bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 cursor-pointer;
  }

  .cc-btn-secondary {
    @apply py-2 px-4 text-center border-blue-600 border-2 text-blue-800 bg-blue-100 font-semibold rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 cursor-pointer;
  }

  .icon {
    @apply w-5 h-5 my-auto;
  }

  .icon-link {
    @apply cursor-pointer transition duration-150 ease-in-out text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600 disabled:text-gray-400 disabled:cursor-default;
  }

  .icon-warning {
    @apply cursor-pointer transition duration-150 ease-in-out text-red-600 hover:text-red-800;
  }
}

/* for https://flowbite.com/docs/components/tooltips/ */
.tooltip-arrow {
  @apply bg-inherit -top-1 h-[8px] absolute w-[8px] transform rotate-45;
}

/* to match flowbite https://flowbite.com/docs/forms/select/ within react-select https://stackoverflow.com/questions/63212723/using-dark-mode-in-react-select */
@layer components {
  .my-react-select-container .my-react-select__control {
    @apply cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply ring-blue-500 border-blue-500 dark:ring-blue-500 dark:border-blue-500;
  }

  .my-react-select-container .my-react-select__menu {
    @apply cursor-pointer bg-gray-100 dark:bg-gray-700 border-2 border-gray-300 dark:border-gray-600;
  }

  .my-react-select-container .my-react-select__option {
    @apply cursor-pointer text-sm px-2 py-1 text-gray-600 dark:text-gray-200 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-800;
  }

  .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-gray-200 dark:bg-gray-800;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-gray-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-gray-600 dark:text-gray-200;
  }

  /* Multi-value container and labels */
  .my-react-select-container .my-react-select__multi-value {
    @apply bg-gray-400 dark:bg-gray-600 rounded-md;
  }

  .my-react-select-container .my-react-select__multi-value__label {
    @apply text-gray-800 dark:text-gray-100;
  }

  /* Multi-value remove button and SVG */
  .my-react-select-container .my-react-select__multi-value__remove {
    @apply bg-gray-500 dark:bg-gray-700 hover:bg-gray-600 dark:hover:bg-gray-800 rounded-full;
  }

  .my-react-select-container
    .my-react-select__multi-value__remove
    .css-tj5bde-Svg {
    @apply text-gray-200 dark:text-gray-400;
  }

  /* Clear and dropdown indicator */
  .my-react-select-container .my-react-select__clear-indicator,
  .my-react-select-container .my-react-select__dropdown-indicator {
    @apply text-gray-400 dark:text-gray-600;
  }

  .my-react-select-container .my-react-select__clear-indicator:hover,
  .my-react-select-container .my-react-select__dropdown-indicator:hover {
    @apply text-gray-500 dark:text-gray-500;
  }

  .my-react-select-container .my-react-select__clear-indicator .css-tj5bde-Svg,
  .my-react-select-container
    .my-react-select__dropdown-indicator
    .css-tj5bde-Svg {
    @apply fill-current;
  }
}

.cursor-pointer-group > * {
  cursor: pointer;
}

/* extending the daisyUI styling */
.btn-gray {
  @apply bg-gray-500 border-gray-500 hover:bg-gray-600 hover:border-gray-600;
}

.btn-tertiary {
  @apply bg-tertiary border-tertiary hover:bg-tertiary-darker hover:border-tertiary-darker;
}

/* for the registration modal */
.btn-quaternary {
  @apply bg-quaternary border-quaternary hover:bg-quaternary-darker hover:border-quaternary-darker;
}

.label-style {
  @apply block text-gray-700 text-sm font-bold mb-2;
}

.input-style {
  @apply border-primary shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.input-style-error {
  @apply border-red-500 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight border-4;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
h1 {
  @apply text-5xl;
}
h2 {
  @apply text-4xl;
}
h3 {
  @apply text-3xl;
}
h4 {
  @apply text-2xl;
}
h5 {
  @apply text-xl;
}
h6 {
  @apply text-lg;
}

p {
  @apply text-gray-500 dark:text-gray-400;
}
p {
  @apply text-sm;
} */

.text-link {
  @apply text-blue-600 hover:text-blue-800 underline cursor-pointer;
}
