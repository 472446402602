/* This changes the react-parallax component to support CMSImage picture elements */
.react-parallax-background-children {
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
}

.react-parallax-background-children picture {
  height: 100%;
}

.react-parallax-background-children picture img {
  height: 100%;
  object-fit: cover;
}
